.filesUploader__ {
    &loader {
        background: rgba(255, 255, 255, .7);
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        display: none;
        align-items: center;
        justify-content: center;

        & svg {
            text-align: center;
            animation: filesUploader__loader__animation 2s infinite;
            width: 32px;
            height: 32px;
            margin: 0 auto;
            display: block;
        }
    }

    &report {
        margin: 0.5rem 0;
        font-size: 0.875rem;
        line-height: 1.25em;
        border-radius: 5px;
        padding: 0.5rem;

        &-success {
            color: var(--bs-success);
            border: 1px solid var(--bs-success);
        }

        &-error {
            color: var(--bs-danger);
            border: 1px solid var(--bs-danger);
        }
    }
}

@keyframes filesUploader__loader__animation {
    from {
        transform: rotate(360deg);
    }
    to {
        transform: rotate(0deg);
    }
}



