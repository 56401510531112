.entrySortableList__{
    &root{
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        &--vertical{
            flex-direction: column;
            margin:0 auto;
            & .entrySortableList__entry{
                flex: 1 1 auto;
                height: auto;
                width:100%;
            }
        }
    }
    &entry{
        flex: 0 0 100px;
        height: 100px;
        position: relative;
        overflow: hidden;
        border: 2px solid transparent;
        border-radius:6px;
        &Target{
            border: 2px solid red;
        }
        & img{
            width:100%;
            height:100%;
            object-fit: cover;
            display:block;
        }
    }
}
