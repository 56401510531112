html, body {
    height: 100%;
}

a {
    text-decoration: none;
    color: #435070;
}

a:hover {
    text-decoration: none;
    color: #0a1638;
}

:root {
    --bs-font-sans-serif: "Roboto", sans-serif;

    --appNavigation__background: white;
    --appNavigation__background-hover: #f0f0f5;
    --appNavigation__color: #617280;
    --appNavigation__color-hover: black;
}


.pagination {
    --bs-pagination-color: #426487;
    --bs-pagination-active-bg: #426487;
    --bs-pagination-active-border-color: #426487;
    --bs-pagination-padding-y: 3px;
    --bs-pagination-padding-x: 12px;
    --bs-pagination-font-size: 1rem;
}

.pagination-summary {
    font-size: 0.75rem;
    text-align: center;
}

.navbar-brand {
    --bs-navbar-brand-padding-y: 0;
    --bs-navbar-brand-margin-y: 0;
}

.navbar {
    --bs-navbar-padding-y: 0;
    --bs-navbar-toggler-padding-y: 0.5rem;
    --bs-navbar-toggler-padding-x: 0.5rem;
    --bs-navbar-toggler-border-radius: 50%;
    --bs-navbar-toggler-focus-width: 4px;
    --bs-border-width: 0;
    --bs-navbar-toggler-border-color: transparent;
}

.navbar-nav {
    --bs-nav-link-color: #313c4b;
    margin-left: 66px;
}

.navbar-toggler:focus {
    outline: none;
    box-shadow: 0 0 4px rgba(19, 28, 160, 1);
}

.navbar-brand {
    flex: 1 1 auto;
    height: 100%;
}

.navbar-brand-image {
    height: 100%;
    max-width: 260px;
}

.navbar-brand-bar {
    height: 60px;
    position: relative;
    background: white;
    width: 100%;
}



.nav-item {
    border-bottom: 1px solid rgba(0, 0, 0, .2);
    padding-left: 0;
}

.nav-item:last-child {
    border-bottom: 0;
}

.page-app {
    display: flex;
    flex-direction: column;
    min-height: 100%;
}

.page-main {
    flex: 1 1 auto;
}

.page-footer {
    background: white;
    border-top: 1px solid rgba(0, 0, 0, .2)
}

.page-footer .navbar-nav {
    margin-left: 0;
    justify-content: center;
}

.page-footer .navbar-brand {
    height: 100px;
    margin: 0.5rem auto;
    text-align: center;
}

.page-footer .nav-item {
    border-bottom: 0;
    white-space: nowrap;
}

.page-footer .nav-item:first-child {
    border-left: 0;
}

.navbar-nav-horizontal {
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 15px;
    margin-bottom: 15px;
    --bs-nav-link-padding-y: 3px;
    margin-left: 66px;
}

.navbar-nav-horizontal .nav-item {
    border-bottom: 0;
    border-left: 1px solid rgba(0, 0, 0, .2);
    padding-left: 8px;
    padding-right: 8px;
}

.nav-breadcrumbs {
    list-style: none;
    padding: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
}

.nav-breadcrumbs li {
    padding: 2px 10px;
    border-left: 1px solid rgba(0, 0, 0, .2);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    min-width: 10px;
}

.page-footer-container {
    display: flex;
    flex-direction: column;
}

.page-footer-container-copyright {
    text-align: center;
    padding-bottom: 1rem;
}

.pageTitle {
    text-align: center;
    margin: 1rem 0 2rem 0;
    padding: 0;

}

.pageTitle h1,
.pageTitle h2,
.pageTitle p {
    font-size: 1.5rem;
    line-height: 1.25em;
    color: #7f180d;
    padding: 5px 15px;
    border-left: 3px solid red;
    border-right: 3px solid red;
    margin-bottom: 0;
}
.pageTitle h1 span{
    display: block;
    font-size: 0.875rem;
    margin-top:0;
    color:black;
}
.pageText {
    font-size: 1rem;
    line-height: 1.45em;
}

.pageText h2 {
    font-size: 1.25rem;
    line-height: 1.25em;
}

.pageImages {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    padding: 1rem 0 0.5rem 0;
    border-top: 1px solid #cfcfcf;
    border-bottom: 1px solid #cfcfcf;
    margin-bottom: 1rem;
}

.pageImages-image {
    flex: 0 0 150px;
    width: 150px;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 2px;
}

.pageImages-image img {
    flex: 0 0 150px;
    height: 150px;
    width: 150px;
    overflow: hidden;
    object-fit: cover;
}

.pageImages-caption {
    height: 1.5rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    min-width: 10px;
    font-size: 0.75rem;
    padding: 2px 0;
}

.promoSection {
    border-radius: 12px;
    background: #d6d9e7;
    padding: 1rem;
    border: 1px solid #a5b3bf;
    margin-bottom: 1rem;
}

.promoSection h2 {
    font-size: 1.25rem;
    line-height: 1.25em;
}

.clientsSection {
    display: flex;
    padding: 1rem 0;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}

.clientsSection img {
    flex: 0 0 auto;
    margin: 4px;
    background: white;
    padding: 0.5rem;
}

.ourSections {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
}

.ourSections a {
    flex: 0 0 150px;
    margin: 2px;
    opacity: 0.5;
    transition: opacity 0.4s;
    line-height: 1.25em;
}

.ourSections a:hover {
    opacity: 1;
}

table.table-in-article {
    border-collapse: collapse;
    font-size: 0.875em;
    line-height: 1.5em;
    margin-bottom: 1em;
}

table.table-in-article th,
table.table-in-article td {
    padding: 5px;
    border: 1px solid #ccc;
}

table.table-in-article th {
    border-collapse: collapse;
    font-size: 0.875em;
    line-height: 1.5em;
    font-weight: bold;
    text-align: center;
}

table.table-in-article tr td:last-child {
    text-align: center;
}

table.table-in-article tr:nth-child(even) {
    background: #e4e4e4;
}

table.table-in-article tr:nth-child(odd) {
    background: #f4f4f4;
}


.pageSubsections {
    display: flex;
    flex-direction: column;
    list-style: none;
    padding:0;
    align-items: stretch;
    margin:0.5rem 0;
}

.pageSubsections li{
    text-align: center;
    padding:5px;
    border-bottom: 1px solid rgba(0, 0, 0, .2);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.pageSubsections.bottom li{
    border-top: 1px solid rgba(0, 0, 0, .2);
    border-bottom: none;
}
.pageSubsections li span{
    font-weight: bold;
}

.galleryCollage {
    max-width: 900px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    max-height:300px;
    overflow: hidden;
    margin:0 auto 1rem auto;
    justify-content: center;
}

.galleryCollage-image {
    flex: 0 0 150px;
    width: 150px;
    height: 150px;
    padding:1px;
}

.galleryCollage-image img {
    width: 100%;
    height: 100%;
    display: block;
    object-fit: cover;
}
/* slideshow */
.slideShow__root {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    padding: 0;
}

.slideShow__viewport {
    order: 1;
    height: 300px;
    overflow: hidden;
    border-bottom:1px solid white;
}

.slideShow__teazers {
    order: 2;
    height: 180px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    transition: transform 0.4s;
}
.slideShow__slides {
    transition: transform 0.4s linear 0.2s;
}
.slideShow__teazer {
    flex: 0 0 230px;
    height: 180px;
    padding: 15px;
    background-color: #901b0f;
    background-image: url(/images/corner-top-right-white-25.png);
    background-repeat: no-repeat;
    background-position: top -25px right -25px;
    transition: background-position 0.4s, background-color 0.4s;
    border-left: 1px solid red;
    color: red;
}

.slideShow__teazer.current {
    background-color: red;
    background-position: top 0 right 0;
    border-left: none;
    color: white;
}

.slideShow__teazer h3 {
    font-size: 1rem;
    line-height: 1.25em;
    font-weight: bold;
}

.slideShow__teazer p {
    font-size: 0.875rem;
    line-height: 1.25em;
}

.slideShow__slide {
    display: flex;
    align-items: center;
    justify-content: center;
}


/* admin navigation */

.appNavigation__nav {
    position: relative;
    width: 100%;
    z-index: 100;
    background: var(--appNavigation__background);
    border-bottom:1px solid rgba(0,0,0,0.2);
}

.appNavigation__container {
    position: relative;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
}

.appNavigation__panel{
    position: fixed;
    left:0;
    width:300px;
    background: white;
    top:0;
    bottom:0;
    box-shadow: 0 0 4px rgba(0,0,0,0.5);
    z-index:200;
    overflow: auto;
    transition: transform 0.4s;
    transform: translateX(-304px);
}
.appNavigation__panel--open{
    transform: translateX(0px);
}
.appNavigation__panel ul{
    list-style: none;
    padding: 0 0 0 0;
}
.appNavigation__panel ul li{
    border:none;
    padding: 0 0 0 0;
}
.appNavigation__panel ul li a{
    font-size:1rem;
    display: block;
    padding:0 10px;
    height:48px;
    line-height:48px;
    border-bottom: 1px solid rgba(0,0,0,0.2);
    transition: background 0.4s;
}
.appNavigation__panel ul li a:hover{
    background: var(--bs-gray-200);
}
.appNavigation__button{
    position: fixed;
    box-shadow: 0 0 4px rgba(0,0,0,0.5);
    z-index:201;
    left:4px;
    background: white;
    top:50%;
    border-radius: 20px;
    width:40px;
    height:40px;
    opacity:0.7;
    cursor: pointer;
    transform:translateY(-50%);
}
.appNavigation__button:hover{
    opacity:1;
}
.appNavigation__menuList{
    display: flex;
    padding:0;
    list-style:none;
    margin:0 0 1rem 0;
    justify-content:center ;
}
.appNavigation__menuList li{
    flex:1 1 auto;
}
.appNavigation__menuList li:first-child{
    text-align: right;
    border-right:1px solid rgba(0,0,0,0.2);
}
.appNavigation__menuList li a{
    text-decoration: none;
    color:var(--bs-gray-600);
    letter-spacing: 2px;
    padding:0 10px;
}


/* app admin list blade*/
.index__listing {
    display: grid;
    grid-gap: 2px;
    align-items: stretch;
    justify-content: center;
}

.index__listing div {
    margin: 0.5rem 0;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    height: 100%;
}

.index__listingHeader {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 100%;
    min-width: 0;
    color: #546182;
    font-size: 0.875rem;
    padding: 0 0.5rem;
    margin: 0 auto;
}


.index__preview {
    width: 104px;
    height: 84px;
    padding: 2px;
}

.index__preview img {
    width: 100px;
    height: 80px;
    object-fit: contain;
    display: block;
}


.index__iconButton {
    width: 32px;
    height: 32px;
    line-height: 32px;
    outline: none;
    border: none;
    background: transparent;
    text-decoration: none;
    text-align: center;
    font-size: 1.5rem;
    vertical-align: baseline;
    color: #0a53be;
}

.index__iconButton:hover {
    color: #bb2d3b;
}


.index__label {
    background: #7d99b3;
    text-decoration: none;
    color: white;
    height: 24px;
    border-radius: 6px;
    padding: 0 5px;
    width: 80px;
    text-align: center;
    transition: all 0.4s;
    line-height: 1.25rem;
}

.index__label:hover {
    background: #0b5ed7;
    color: white;
    box-shadow: 0 0 4px rgba(0, 0, 0, .5);
}
/* VIDEO */
.videos {
    padding: 0.5rem;
}
/* news articles*/
.siblings {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    border-top: 1px solid rgba(0, 0, 0, .2);
    padding: 1rem 0;
    margin: 1rem 0 0 0;
}

.sibling {
    flex: 0 0 50%;
    padding: 4px;
    position: relative;
    overflow: hidden;
    text-align: end;
}


.sibling--next .sibling__name {
    padding: 0 0 0 0.5rem;
    border-right: none;
    border-left: 1px solid rgba(0, 0, 0, .2);
}

.sibling--next {
    text-align: start;
}


.sibling img {
    flex: 0 0 100px;
    width: 100px;
    height: 100px;
    object-fit: cover;
    box-shadow: 0 0 2px rgba(0, 0, 0, .4);
}

.article {
    display: flex;
    position: relative;
    flex-direction: column;
}

.article__previews {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    margin: 0.5rem 0;
    flex-wrap: wrap;
}

.article__preview {
    flex: 0 0 50px;
    margin: 0;
    overflow: hidden;
    border: 1px solid var(--bs-body-bg);
    background: var(--bs-body-bg);
    height: 50px;
    cursor: pointer;
}

.article__preview img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.article__preview--current {
    border: 1px solid var(--bs-red);
}

.article__preview--current img {
    opacity: 0.5;
}

.article__image {
    max-width: 100%;
    margin: 0 auto;
    max-height: 90vh;
    position: relative;
    display: flex;
    align-items: stretch;
    justify-content: center;

}

.article__imageContainer {
    display: flex;
    align-items: center;
    justify-content: center;
}

.article__image img {
    max-width: 100%;
    max-height: 100%;
    display: block;
    box-shadow: 0 0 4px rgba(0, 0, 0, .4);
    object-fit: contain;
}


.articleCards{
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: flex-start;
    margin-bottom:1.5rem;
}
.articleCard{
    display: flex;
    flex-direction: row;
    margin:10px 2px;
}
.articleCard__image{
    flex:0 0 200px;
    width:200px;
    max-height:200px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
}
.articleCard__image img{
    max-height:100%;
    max-width:100%;
}
.articleCard__info{
    text-align: start;
    padding: 0 0 0 1rem;
}
.articleCard__date{
    background: #ccd3e2;
    padding:3px;
    width:100px;
    text-align: center;
    margin-bottom:1rem;
}
.articleCard__name{
    text-align: start;
    font-size:1.25rem;
    line-height: 1.25em;
    font-weight: bold;
    margin-bottom:0.5rem;
}
.articleCard__subname{
    font-size:1rem;
    line-height: 1.25em;
}


.video {
    max-width: 516px;
    margin: 0.5rem auto;
    position: relative;
    border: 1px solid #c9a5a3;
}

.videoContainer {
    width: 100%;
    height: 0;
    position: relative;
    padding-bottom: 56.2%;
}

.videoContainer__frame {
    position: absolute;
    inset: 0;
    padding: 0;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    justify-content: center;
}

.videoContainer___playButton {
    flex: 0 0 80px;
    cursor: pointer;
}


/* contacts */
.contacts {
    display: flex;
    flex-direction: column;
    text-align: center;
}

.contacts__person {
    text-align: center;
}


/* browse product */
.productCards{
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    margin-bottom:1.5rem;
}
.productCard{
    flex:0 0 220px;
    width:220px;
    background: #dcdeec;
    padding:10px;
    margin:2px;
}
.productCard__image{
    width:200px;
    height:200px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
}
.productCard__image img{
    max-height:100%;
    max-width:100%;
}
.productCard__name{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
    padding:5px 0 0 0;
}








.product {
    display: flex;
    position: relative;
    flex-direction: column;
}


.product__preview {
    flex: 0 0 50px;
    margin: 1px;
    border-radius: 50%;
    overflow: hidden;
    border: 2px solid var(--bs-body-bg);
    background: var(--bs-body-bg);
    height: 50px;
    cursor: pointer;
}

.product__preview img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.product__preview--current {
    border: 2px solid var(--bs-red);
}

.product__preview--current img {
    opacity: 0.5;
}

.product__image {
    max-width: 100%;
    margin: 0 auto;
}

.product__image img {
    max-width: 100%;
    box-shadow: 0 0 4px rgba(0, 0, 0, .4);
}








.sibling__name {
    flex: 0 0 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    min-width: 10px;
    width: 100%;
    font-size: 0.75rem;
    padding: 0 0.5rem 0 0;
    border-right:1px solid rgba(0,0,0,.2);
    margin:0.5rem 0;
}



.product__previews {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    margin: 0.5rem 0;
    flex-wrap: wrap;
}



@media (min-width: 768px) {

    .product__previews {
        margin: 1rem 0;
    }

    .product__preview {
        flex: 0 0 80px;
        margin: 2px;
        border: 4px solid var(--bs-body-bg);
        height: 80px;
    }
    .product__preview--current {
        border: 4px solid var(--bs-red);
    }
    .sibling {
        margin: 4px;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: flex-start;
    }

    .sibling img {
        order: 2;
        flex: 0 0 150px;
        width: 150px;
        height: 150px;
    }

    .sibling__name {
        flex: 1 1 auto;
        order: 1;
        padding: 0 0.5rem 0 0;
        margin: 0 0.5rem 0 0;
    }

    .sibling--next .sibling__name {
        padding: 0 0 0 0.5rem;
        margin: 0 0 0 0.5rem;
        order: 2;
    }

    .sibling--next img {
        order: 1;
        text-align: start;
    }


    .article__previews {
        margin: 1rem 0;
    }

    .article__preview {
        flex: 0 0 80px;
        border: 2px solid var(--bs-body-bg);
        height: 80px;
    }

    .article__preview--current {
        border: 2px solid var(--bs-red);
    }
    .navbar-menu-control {
        display: none;
    }

    .nav-item {
        border-bottom: 0;
        border-left: 1px solid rgba(0, 0, 0, .2);
        padding-left: 8px;
        padding-right: 8px;
    }

    .navbar-nav {
        margin-top: 5px;
        margin-bottom: 5px;
        --bs-nav-link-padding-y: 3px;
    }

    .page-footer-container {
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: flex-start;
    }

    .page-footer-container .navbar-nav {
        justify-content: flex-start;
    }

    .page-footer .nav-item:first-child {
        border-left: 1px solid rgba(0, 0, 0, .2);
    }

    .page-footer-container-copyright {
        flex: 0 0 250px;
        padding: 1rem 2rem;
    }

    .page-footer-container-info {
        flex: 1 1 auto;
    }
}

@media screen and (min-width: 900px) {
    .pageTitle {
        text-align: left;
        padding-left: 250px;
    }

    .pageTitle h1,
    .pageTitle h2,
    .pageTitle p {
        border-right: none;
    }
    .pageSubsections {
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: center;

    }
    .pageSubsections li{
        border-left: 1px solid rgba(0, 0, 0, .2);
        border-bottom: none;
        padding:5px 15px;
    }
    .pageSubsections.bottom li{
        border-top: none;
    }
    .pageSubsections li:first-child{
        border-left: none;
    }
    .withOffset {
        padding-left: 250px;
    }
    .contacts {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: flex-start;
        justify-content: flex-start;
        text-align: left;
    }

    .contacts__info {
        flex: 0 0 250px;
        padding: 0 1rem 0 0;
    }

    .contacts__map {
        flex: 1 1 auto;
    }

    .contacts__person {
        text-align: left;
    }
    .videos {
        padding: 0.5rem 0 0.5rem 250px;
    }
    .video {
        margin: 0.5rem 0;
    }

    .slideShow__root {
        flex-direction: row;
        height: 300px;
    }

    .slideShow__teazers {
        order: 1;
        flex: 0 0 250px;
        height: auto;
        flex-direction: column;

    }

    .slideShow__viewport {
        order: 2;
        flex: 1 1 auto;
        border-left:1px solid white;
        border-bottom:none;
    }

    .slideShow__teazer {
        flex: 0 0 150px;
        height: 150px;
        border-top: 1px solid red;
        border-left: none;
    }

    .slideShow__teazer:first-child {
        border-left: none;
        border-top: none;
    }

}
@media only screen and (min-width: 992px) {
    .appNavigation__socialList {
        flex-direction: row;
    }
    .appNavigation__container {
        flex-direction: row;
        align-items: center;
        background: white;
    }
    .appNavigation__navLogo {
        flex: 0 0 289px;
        margin:0;
    }
    .appNavigation__navLogo img {
        width: 100%;
    }
    .appNavigation__menuList{
        margin:0;
    }
    .appNavigation__menuList li{
        flex:0 0 auto;
    }
}
@media screen and (min-width: 1200px) {
    .slideShow__slide {
        justify-content: flex-start;
    }
}
